/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$fg-primary-rgb: 253, 255, 253;
$fg-secondary-rgb: 10, 10, 10;
$bg-primary-rgb: 51, 61, 71;
$bg-secondary-rgb: 255, 131, 0;

$fg-primary: #fdfffd;
$fg-secondary: #0a0a0a;
$bg-primary: #333d47;
$bg-secondary: #ff8300;
$bg-tertiary: #c77823;

$pic-pixel: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==);

:root,
.md,
.ios {
  --ion-font-family: "Helvetica Neue", "Roboto", sans-serif;

  --ion-background-color: #333d47;
  --ion-background-color-rgb: 51, 61, 71;

  --ion-text-color: #fdfffd;
  --ion-text-color-rgb: 253, 255, 253;

  --ion-item-color: #fdfffd;
  --ion-item-color-rgb: 253, 255, 253;

  --ion-toolbar-segment-indicator-color: #ff8300;
  --ion-toolbar-segment-indicator-color-rgb: 255, 131, 0;
  --ion-toolbar-segment-color-checked: #ff8300;
  --ion-toolbar-segment-color-checked-rgb: 255, 131, 0;
  --color-checked: #ff8300;
  --color-checked-rgb: 255, 131, 0;

  --ion-color-primary: #ff8300;
  --ion-color-primary-rgb: 255, 131, 0;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e07300;
  --ion-color-primary-tint: #ff8f1a;

  --ion-color-secondary: #238d00;
  --ion-color-secondary-rgb: 35, 141, 0;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1f7c00;
  --ion-color-secondary-tint: #39981a;

  --ion-color-tertiary: #956745;
  --ion-color-tertiary-rgb: 149, 103, 69;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #835b3d;
  --ion-color-tertiary-tint: #a07658;

  --ion-color-success: #00755f;
  --ion-color-success-rgb: 0, 117, 95;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #006754;
  --ion-color-success-tint: #1a836f;

  --ion-color-warning: #bf8f00;
  --ion-color-warning-rgb: 191, 143, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #a87e00;
  --ion-color-warning-tint: #c59a1a;

  --ion-color-danger: #660000;
  --ion-color-danger-rgb: 102, 0, 0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #5a0000;
  --ion-color-danger-tint: #751a1a;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

:root,
.md,
.ios {
  --steel-darkest: #2e3740;
  --steel-dark: #4c5761;
  --steel-medium-dark: #707982;
  --steel-medium: #89929c;
  --steel-medium-light: #a9b2ba;
  --steel-light: #ccd2d9;
  --steel-lightest: #f0f2f5;

  .control-code {
    text-transform: unset !important;
    opacity: 1;
  }
  .control-code-l {
    --background: var(--steel-medium-light);
  }
  .control-code-n {
    --background: #ff3333;
  }
  .control-code-p {
    --background: #ffa033;
  }
  .control-code-f {
    --background: #ffe033;
  }
  .control-code-g {
    --background: #92cc58;
  }
  .control-code-e {
    --background: #1d8f1d;
    --color: white;
  }

  // Styles select modals (this is the only place that works)
  ion-alert.select-alert {
    .alert-radio-label {
      color: white !important;
    }
    .alert-wrapper {
      width: 100%;
    }
  }

  // Styles selectable modals (this is the only place that works)
  ionic-selectable-modal {
    .searchbar-input {
      color: white !important;
    }
    ion-searchbar {
      --icon-color: var(--steel-medium);
      --placeholder-color: var(--steel-lightest);
      --color: white;
      --background: var(--steel-dark);
    }
    ion-header ion-toolbar {
      --background: var(--steel-darkest);
    }
  }
}

ion-alert.prompt-alert {
  .alert-message {
    color: #CCD2D9;
  }

  .continue-button {
    background-color: #FF8300;
    color: black;
    border-radius: 4px;
  }

  .cancel-button {
    color: white;
  }
}
